import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter
}from 'react-router-dom';
import {renderRoutes} from 'react-router-config';
import {
  Provider
} from 'react-redux';
import routes from './routes/routes';
import configureStore from './core/configureStore';

import * as serviceWorker from './serviceWorker';

const appDomain = '';
const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter basename={appDomain}>
      {renderRoutes(routes)}
    </BrowserRouter>
  </Provider>, 
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
