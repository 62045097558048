import React, {
  Component
} from 'react';
import {
  connect
} from 'react-redux';
import initReactFastclick from 'react-fastclick';
import ReactGA from 'react-ga';
import {renderRoutes} from 'react-router-config';

import IModal from '../../components/global/modal/Index';
import IToastr from '../../components/global/toastr/Index';
import Loading from '../../components/global/loading/Index';
import Header from '../global/header/Index';
import Freeweek from '../../components/index/free-week/Index';
import Nav from '../../components/global/nav/Index';
import * as actions from './actions';
require('../../assets/css/style.scss');
require('./style.scss');

initReactFastclick();

class App extends Component {
  componentDidMount() {
    var self = this;
      // var scale = (1 / devicePixelRatio)*2;
      // document.querySelector('meta[name="viewport"]').setAttribute('content','initial-scale=' + scale + ', maximum-scale=' + scale + ', minimum-scale=' + scale + ', user-scalable=no');
    (function (window) {
      var winW = document.documentElement.clientWidth || document.body.clientWidth;
      document.documentElement.style.fontSize = winW / 3.75 + "px";
      //当页面大小发生改变,重新修正rem为新窗口尺寸的3.75分之一
      window.onresize = function () {
        var wid = document.documentElement.clientWidth || document.body.clientWidth;
        if (wid > 1024) {
          wid = 1024;
        } else if (wid < 320) {
          wid = 320;
        }
        document.documentElement.style.fontSize = wid / 3.75 + "px";
      };
    })(window);
    this.props.getUserInfo();
    //let lang = this.location.param.lang || '';
  }
  render() {

    // TODO: 判断服务器错误502
    // TODO: 判断用户是否已经登陆
    // 如果未登陆取默认头像
    // 如果登陆即获取用户信息

    var { home } = this.props;
    return (
      <div>
        <div className="header">
          {this.props.location.pathname.split('/')[1] === '' ? <Header /> : <Header white={true} />}
        </div>
        <div className="main">
          {renderRoutes(this.props.route.routes)}
        </div>
        <Freeweek />
        <Nav />
        <div className="privacy">© 忒伊亚（天津）科技有限公司 | 津ICP备2024020729号-1</div>
        <IToastr content={home.toastr}
          isOpen={home.showToastr}/>
        <Loading content={home.loading}
          isOpen={home.showLoading}/>
        <IModal
          title={home.alert.title}
          content={home.alert.msg}
          type="alert"
          isOpen={home.showAlert}
          onClose={()=>{this.props.hideAlert();}}/>
      </div>
    );
  }
}

export default connect(state => {
  return {
    home: state.home
  };
}, dispatch => {
  return {
    getUserInfo: () => {
      dispatch(actions.getUserInfo());
    },
    hideAlert: () => {
      dispatch(actions.hideAlert());
    }
  };
})(App);
