import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import DocumentTitle from 'react-document-title';

import Utils from '../../services/utils';
import Nav from '../../components/global/nav/Index';
import Prof from '../../components/user-edit/prof/Index';
import Company from '../../components/user-edit/company/Index';
import School from '../../components/user-edit/school/Index';
import Request from '../../services/request';

import * as homeActions from '../home/actions';

require('./style.scss');

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canSubmit: false,
      countTime: 0,
      tab: this.props.pathname
    };
  }
  componentDidMount() {
      // console.log(this.state.lesson);
  }
  render() {
    let { home } = this.props;
    let pathname = this.props.location.pathname.split('/')[2];
    if (!home.userInfo && !home.userInfo.u_name) {
      return null;
    }
    return (
      <DocumentTitle title={Utils.title()}>
        <div>
          <div className="container page-user-edit">
            <div className="page-header">
              <p className="edit-title">信息更新</p>
              <ul className="nav">
                <li className={pathname === 'prof' ? "active" : null}><Link to="/user-edit/prof" ><span>个人信息</span></Link></li>
                <li className={pathname === 'school' ? "active" : null}><Link to="/user-edit/school" ><span>学校信息</span></Link></li>
                <li className={pathname === 'company' ? "active" : null}><Link to="/user-edit/company" ><span>公司信息</span></Link></li>
              </ul>
            </div>
            {pathname === 'prof' ? <Prof /> : null}
            {pathname === 'company' ? <Company /> : null}
            {pathname === 'school' ? <School /> : null}
            <Nav />
            <div className="privacy">© 蝙蝠工场 2019</div>
          </div>
        </div>
      </DocumentTitle>
    ); 
  }
}

export default connect(state => {
  return {
    home: state.home
  };
}, dispatch => {
  return {
    toastr: (msg) => {
      dispatch(homeActions.toastr(msg));
    }
  };
})(Index);
