import React, { Component } from "react";
import { connect } from "react-redux";
import DocumentTitle from "react-document-title";

import Utils from "../../services/utils";
import Nav from "../../components/global/nav/Index";
import Request from "../../services/request";

import * as homeActions from "../home/actions";
import { withRouter } from "react-router-dom";


class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canSubmit: false,
      countTime: 0,
    };
  }
  componentDidMount() {
    // console.log(this.state.lesson);
  }
  onSubmit() {
    let dataSend = {
      telphone: this.refs.telphone.value,
      password: this.refs.password.value,
      password2: this.refs.password2.value,
      verifycode: this.refs.verifycode.value,
      name: this.refs.name.value || '',
      inviteCode: Utils.getCookie('inviteCode')
    };
    Request("/api/users/regist", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(dataSend),
    })
      .then((data) => {
        if (data.code == 0) {
          this.props.history.push("/");
        } else {
          this.props.toastr(data.msg);
        }
      })
      .catch((err) => {
        this.props.toastr(err.msg);
      });
  }
  onType() {
    let data = {
      telphone: this.refs.telphone.value,
      password: this.refs.password.value,
      password2: this.refs.password2.value,
      verifycode: this.refs.verifycode.value,
      name: this.refs.name.value,
    };
    if (
      /(^1[3|4|5|7|8]\d{9}$)|(^09\d{8}$)/.test(data.telphone) &&
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,16}$/.test(data.password) &&
      data.password === data.password2 &&
      this.refs.checkbox.checked &&
      data.verifycode
    ) {
      this.setState({
        canSubmit: true,
      });
    } else {
      this.setState({
        canSubmit: false,
      });
    }
  }
  verifyPhone() {
    let ct = 60;
    if (!/(^1[3|4|5|7|8]\d{9}$)|(^09\d{8}$)/.test(this.refs.telphone.value)) {
      return false;
    }
    this.doGetVerify(this.refs.telphone.value);
    this.setState({
      countTime: 60,
    });
    let countTimeInt = setInterval(() => {
      // console.log(this.state.countTime);
      if (ct == 0) {
        clearInterval(countTimeInt);
        return false;
      }
      ct--;
      this.setState({
        countTime: ct,
      });
    }, 1000);
  }
  doGetVerify(phone) {
    Request("/api/sendvc?phone=" + phone).then(
      (data) => {
        console.log(`send msm to ${phone}`);
      },
      (err) => {}
    );
  }
  render() {
    var { home } = this.props;
    return (
      <DocumentTitle title={Utils.title()}>
        <div>
          <div className="container page-demo">
            <p className="page-title">用户注册</p>
            <div className="demo-form">
              <p className="from-title">手机号码：</p>
              <input
                type="text"
                placeholder="填写手机号码"
                ref="telphone"
                onInput={this.onType.bind(this)}
              />
              <div className="verify-box">
                <input
                  className="verifycode"
                  type="text"
                  placeholder="填写验证码"
                  ref="verifycode"
                  onInput={this.onType.bind(this)}
                />
                {this.state.countTime == 0 ? (
                  <div
                    className="verifybtnactive"
                    onClick={this.verifyPhone.bind(this)}
                  >
                    发送验证码
                  </div>
                ) : (
                  <div className="verifybtn">
                    {this.state.countTime}s后重新获取
                  </div>
                )}
              </div>
              <p className="from-title">设置密码：</p>
              <input
                type="password"
                placeholder="包含数字、大写字母和小写字母"
                ref="password"
                onInput={this.onType.bind(this)}
              />
              <p className="from-title">确认密码：</p>
              <input
                type="password"
                placeholder="再次输入密码"
                ref="password2"
                onInput={this.onType.bind(this)}
              />
              <p className="from-title">用户名（姓名）：</p>
              <input
                type="text"
                placeholder="填写您的姓名方便课程安排"
                ref="name"
                onInput={this.onType.bind(this)}
              />
              <label className="rights">
                <input
                  type="checkbox"
                  ref="checkbox"
                  defaultChecked={true}
                  onChange={this.onType.bind(this)}
                />
                <span>阅读并接受</span>{" "}
                <a href="/help/terms">《THEIA用户协议》</a>
              </label>
              {this.state.canSubmit ? (
                <div
                  className="btn-sub-demo"
                  onClick={this.onSubmit.bind(this)}
                >
                  提交注册
                </div>
              ) : (
                <div className="btn-sub-demo grey">提交注册</div>
              )}
            </div>
            <Nav />
            <div className="privacy">© 蝙蝠工场 2019</div>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

export default connect(
  (state) => {
    return {
      home: state.home,
    };
  },
  (dispatch) => {
    return {
      toastr: (msg) => {
        dispatch(homeActions.toastr(msg));
      },
    };
  }
)(withRouter(Index));
