import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Picker, List, WhiteSpace, DatePicker } from 'antd-mobile';
import constants from '../../../constants/options';
import Utils from '../../../services/utils';
import Request from '../../../services/request';
import {withRouter} from "react-router-dom";

import * as homeActions from '../../../containers/home/actions';
import company from '../../../routes/user-edit/company';
//import 'antd-mobile/dist/antd-mobile.css';

require('../../user/prof/style.scss');
require('./style.scss');

const levelOptions = [
  {
    label: '博士',
    value: '0',
  },
  {
    label: '硕士',
    value: '1',
  },
  {
    label: '本科',
    value: '2',
  },
  {
    label: '专科',
    value: '3',
  },
  {
    label: '高中',
    value: '4',
  },
  {
    label: '其它',
    value: '5',
  }
];

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: '',
      companyArr: [],
      editData: {
        company_name: '',
        company_sub_name: '',
        start_time: '',
        end_time: '',
      }
    };
  }
  componentDidMount() {
    const { home } = this.props;
    if (home.userInfo && home.userInfo.companyData) {
      this.setState({
        companyArr: home.userInfo.companyData || []
      });
    }
  }
  componentDidUpdate() {
    let editData = this.state.editData;
    for (let key in editData) {
      if (this.refs[key]) {
        this.refs[key].value = editData[key];
      }
    }
  }
  componentWillReceiveProps (nextProps) {
    if (this.props.home.userInfo !== nextProps.home.userInfo) {
      const { home } = nextProps;
      if (home.userInfo && home.userInfo.companyData) {
        this.setState({
          companyArr: home.userInfo.companyData || []
        });
      }
    }
  }
  onAdd (key) {
    const editData = this.state.editData;
    const companyArr = this.state.companyArr;
    const { toastr } = this.props;
    if (!editData.company_name) {
      toastr('请填写公司名称！');
      return false;
    } else if (!editData.company_sub_name) {
      toastr('请填写担任职位！');
      return false;
    } else if (!editData.start_time) {
      toastr('请选择入职时间！');
      return false;
    }
    if (key !== '') {
      companyArr[key] = editData
    } else {
      companyArr.push(editData)
    }
    this.setState({
      isEdit: '',
      companyArr,
    })
  }
  onDelete (key) {
    const companyArr = this.state.companyArr;
    companyArr.splice(key, 1);
    this.setState({
      isEdit: '',
      companyArr,
    })
  }
  onEdit (idx) {
    const companyArr = this.state.companyArr;
    this.setState({
      isEdit: idx,
      editData: companyArr[idx],
    })
  }
  onSubmit() {
    const postData = this.state.companyArr;
    const { toastr, getUserInfo } = this.props;
    Request('/api/users/saveCompany', {
      method: 'POST',
      headers: {
        "Content-type": 'application/json'
      },
      body: JSON.stringify(postData)
    })
    .then(data => {
      if (data.code + '' === '0') {
        toastr('信息已更新！');
        getUserInfo();
      } else {
        toastr('信息保存失败，请稍后重试！');
      }
    })
    .catch((err) => {
      if (err.status - 0 === 401) {
        this.props.history.push("/login");
      }
    });
  }
  resetEditData (val) {
    this.setState({
      editData: val || {
        company_name: '',
        company_sub_name: '',
        level: '',
        start_time: '',
        end_time: '',
      },
    })
  }
  renderEdit (idx) {
    let editData = this.state.editData;
    let key = idx !== false ? idx : '';
    return (
      <div className="company-edit">
        <div className="user-list">
          <div className="user-label">公司：</div>
          <div className="user-detail"><input type="text" placeholder="请填写公司名称" ref="company_name" onChange={(e) => {
            editData.company_name = e.target.value
            this.setState({ editData })
          }} /></div>
        </div>
        <div className="user-list">
          <div className="user-label">职位：</div>
          <div className="user-detail"><input type="text" placeholder="请填写担任职位" ref="company_sub_name" onChange={(e) => {
            editData.company_sub_name = e.target.value
            this.setState({ editData })
          }} /></div>
        </div>
        <DatePicker
          mode="date"
          value={this.state.editData.start_time ? new Date(editData.start_time) : ''}
          onChange={date => {
            editData.start_time = date.getTime();
            this.setState({ editData })
          }}
        >
          <List.Item arrow="horizontal">入职时间：</List.Item>
        </DatePicker>
        <DatePicker
          mode="date"
          value={this.state.editData.end_time ? new Date(editData.end_time) : ''}
          onChange={date => {
            editData.end_time = date.getTime();
            this.setState({ editData })
          }}
        >
          <List.Item arrow="horizontal">离职时间：</List.Item>
        </DatePicker>
        <div className="btn-box">
          <div className="btn-sub-demo btn-add" onClick={this.onAdd.bind(this, key)}>添加</div>
          {key !== '' ? <div className="btn-sub-demo btn-cancel" onClick={this.onDelete.bind(this, key)}>删除</div> : null}
          <div className="btn-sub-demo btn-cancel"
            onClick={() => {
              this.setState({
                isEdit: ''
              })
              this.resetEditData();
            }}>取消</div>
        </div>
      </div>
    )
  }
  render () {
    const { home } = this.props;
    let sexDesc = '';
    let skillArr = [];
    if (!home.userInfo && !home.userInfo.u_name) {
      return null;
    }
    return (
      <div className="user-edit-prof user-edit-company">
        <div className="user-company">
          <ul>
            {this.state.companyArr.length ?
              this.state.companyArr.map((item, idx) => {
                console.log(item, this.state.isEdit, idx)
                if (this.state.isEdit === idx) {
                  return this.renderEdit(idx + '');
                } else {
                  return (
                    <li key={idx}>
                      <div className="user-info">
                        <div className="user-left">公司：{item.company_name || '暂未填写'}</div>
                      </div>
                      <div className="user-info">
                        <div className="user-left">职位：{item.company_sub_name || '暂未填写'}</div>
                      </div>
                      <div className="user-info">
                        <div className="user-left">在职时间：{Utils.formatDate(item.start_time || '', '/')} 至 {Utils.formatDate(item.end_time || '', '/')}</div>
                      </div>
                      <div className="btn-box">
                        <div className="btn-sub-demo btn-add" onClick={this.onEdit.bind(this, idx)}>编辑&修改</div>
                      </div>
                    </li>
                  );
                }
              })
              : <p className="notice">暂无工作记录，点击按钮开始添加！</p>
            }
          </ul>
          {this.state.isEdit === '' ?
            <div className="add-company"
              onClick={() => {
                this.resetEditData();
                this.setState({
                  isEdit: 'new',
                });
              }}>添加工作经历 +</div>
            : null
          }
          {this.state.isEdit === 'new' ?
            this.renderEdit(false)
            : null
          }
        </div>
        <div className="btn-sub-demo" onClick={this.onSubmit.bind(this)}>提交修改</div>
      </div>
    ); 
  }
}

export default connect(state => {
  return {
    home: state.home
  };
}, dispatch => {
  return {
    getUserInfo: () => {
      dispatch(homeActions.getUserInfo());
    },
    toastr: (msg) => {
      dispatch(homeActions.toastr(msg));
    }
  };
})(withRouter(Index));
