import React, { Component } from 'react';
import { connect } from 'react-redux';
import constants from '../../../constants/options';
import Utils from '../../../services/utils';

import * as homeActions from '../../../containers/home/actions';

require('./style.scss');

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentDidMount() {
      // console.log(this.state.lesson);
  }
  render() {
    let { home } = this.props;
    let sexDesc = '';
    let skillArr = [];
    if (!home.userInfo && !home.userInfo.u_name) {
      return null;
    }
    if (home.userInfo.sex === 0) {
      sexDesc = '男';
    } else if (home.userInfo.sex === 1) {
      sexDesc = '女';
    }
    if (home.userInfo.subject) {
      skillArr = home.userInfo.subject.split(',');
    }
    return (
      <div className="user-prof">
        <div className="user-list">
          <div className="user-label">用户名：</div>
          <div className="user-detail">{home.userInfo.u_name || ''}</div>
        </div>
        <div className="user-list">
          <div className="user-label">手机：</div>
          <div className="user-detail">{home.userInfo.telphone || ''}</div>
        </div>
        <div className="user-list">
          <div className="user-label">性别</div>
          <div className="user-detail">{sexDesc}</div>
        </div>
        <div className="user-list">
          <div className="user-label">邮箱：</div>
          <div className="user-detail">{home.userInfo.email || ''}</div>
        </div>
        <div className="user-list">
          <div className="user-label">QQ：</div>
          <div className="user-detail">{home.userInfo.qq || ''}</div>
        </div>
        <div className="user-list">
          <div className="user-label">联系地址：</div>
          <div className="user-detail">{home.userInfo.address || ''}</div>
        </div>
        <div className="user-skill">
          <div className="user-label">技能：</div>
          <div className="user-info">
            {skillArr.length ? skillArr.map((item, idx) => {
              return (<span className="data-item" key={idx}>{item}</span>);
            })
            : <span className="nodata">暂无记录</span>
            }
          </div>
        </div>
        <div className="user-school">
          <div className="user-label">学校情况：</div>
          <ul>
            {home.userInfo.schoolData && home.userInfo.schoolData.length ? home.userInfo.schoolData.map((item, idx) => {
              return (
                <li key={idx}>
                  <div className="user-info">
                    <div className="user-left">学校：{item.school_name || '暂未填写'}</div><div className="user-right">{item.level_desc}</div>
                  </div>
                  <div className="user-info">
                    <div className="user-left">专业：{item.school_sub_name || '暂未填写'}</div>
                  </div>
                  <div className="user-info">
                    <div className="user-left">就读时间：{Utils.formatDate(item.start_time || '')} 至 {Utils.formatDate(item.end_time || '')}</div>
                  </div>
                </li>
              );})
              : <span className="nodata">暂无记录</span>
            }
          </ul>
        </div>
        <div className="user-company">
          <div className="user-label">工作情况：</div>
          <ul>
            {home.userInfo.companyData && home.userInfo.companyData.length ? home.userInfo.companyData.map((item, idx) => {
              return (
                <li key={idx}>
                  <div className="user-info">
                    <div className="user-left">公司：{item.company_name || '暂未填写'}</div>
                  </div>
                  <div className="user-info">
                    <div className="user-left">职位：{item.company_sub_name || '暂未填写'}</div>
                  </div>
                  <div className="user-info">
                    <div className="user-left">在职时间：{Utils.formatDatetime(item.start_time || '')} 至 {Utils.formatDatetime(item.end_time || '')}</div>
                  </div>
                </li>
              );})
              : <span className="nodata">暂无记录</span>
            }
          </ul>
        </div>
        <p className="goto-edit">
          <a href="/user-edit/prof">修改用户信息 ›</a>
        </p>
      </div>
    ); 
  }
}

export default connect(state => {
  return {
    home: state.home
  };
}, dispatch => {
  return {
    toastr: (msg) => {
      dispatch(homeActions.toastr(msg));
    }
  };
})(Index);
