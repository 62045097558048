import React, { Component } from "react";
import { connect } from "react-redux";

require("./style.scss");

class Index extends Component {
  componentDidMount() {}

  render() {
    var { home } = this.props;
    return (
      <div className="components-qa child-help">
        <div className="selection-1">
          <p className="subtitle">
            算力平台-基石智算：https://coreshub.cn/
          </p>
          <p className="subtitle">
            HuggingFace：https://huggingface.co/
          </p>
          <p className="subtitle">
            魔搭社区：https://modelscope.cn/home
          </p>
          <p className="subtitle">
            基石模型库：https://console.coreshub.cn/tjzs1/maas/models/
          </p>
          <p className="subtitle">
            基石镜像库：https://console.coreshub.cn/tjzs1/qai/qai_images
          </p>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => {
    return {
      home: state.home,
    };
  },
  (dispatch) => {
    return {};
  }
)(Index);
