import {
  createStore,
  applyMiddleware
} from 'redux';
import thunkMiddleware from 'redux-thunk';
import logger from 'redux-logger'
import rootReducer from './reducers';

let createStoreWithMiddleware = process.env.NODE_ENV === 'development' ?
  applyMiddleware(
    thunkMiddleware,
    logger
  )(createStore) :
  applyMiddleware(
    thunkMiddleware
  )(createStore);

export default function configureStore(initialState) {
  return createStoreWithMiddleware(rootReducer, initialState);
}
