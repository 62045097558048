import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';

import Utils from '../../services/utils';
import Banner from '../../components/index/banner/Index';
import Container1 from '../../components/index/container-1/Index';
import Container2 from '../../components/index/container-2/Index';
import Container3 from '../../components/index/container-3/Index';

import * as homeActions from '../home/actions';

require('./style.scss');

class Index extends Component {
  componentDidMount() {
    
  }

  render() {
    var { home } = this.props;
    return (
      <DocumentTitle title={Utils.title()}>
        <div>
          <Banner/>
          <div className="container page-index">
            <Container1 />
            <Container2 />
            <Container3 />
            {/*<News />
            <Partner />*/}
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

export default connect(state => {
  return {
    home: state.home
  };
}, dispatch => {
  return {
  };
})(Index);
