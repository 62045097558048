import React, { Component } from "react";
import { connect } from "react-redux";

require("./style.scss");
let mapImg = require("../../../assets/img/help/map.png");

class Index extends Component {
  componentDidMount() {}

  render() {
    var { home } = this.props;
    return (
      <div className="components-contact child-solution">
        <div className="selection-1">
          <p className="title">联系我们</p>
          <p className="subtitle2">
            忒伊亚（天津）科技有限公司
            <br />
            天津武清经济技术开发区高村科技创新园汇滨道南侧清数科技园C座2154室
            <br />
            Email：official@itheia.cn
            <br />
            <br />
          </p>
          <p className="title">助力各领域的AI能力转型</p>
          <img src={mapImg} className="map-img" />
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => {
    return {
      home: state.home,
    };
  },
  (dispatch) => {
    return {};
  }
)(Index);
