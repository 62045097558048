import React, { Component } from "react";
import { connect } from "react-redux";
import DocumentTitle from "react-document-title";

import Utils from "../../services/utils";
import Nav from "../../components/global/nav/Index";
import Request from "../../services/request";
import { withRouter } from "react-router-dom";
import VerifyCode from "../../components/verify-code/Index";
import * as homeActions from "../home/actions";

require("./style.scss");

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canSubmit: false,
      countTime: 0,
      type: 1,
      telphone: "",
      password: "",
      verifyCode: "",
      rights: true,
    };
  }
  componentDidMount() {
    // console.log(this.state.lesson);
  }
  onSubmit() {
    let dataSend = {
      telphone: this.state.telphone,
      password: this.state.password,
      verifyCode: this.state.verifyCode,
      type: this.state.type === 1 ? 'password' : 'verify',
    };
    Request("/api/users/login", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(dataSend),
    })
      .then((data) => {
        if (data.code === 0) {
          this.props.history.push("/");
          this.props.getUserInfo();
        } else {
          this.props.toastr(data.msg);
        }
      })
      .catch((err) => {
        this.props.toastr(err.msg);
      });
  }
  checkCanSubmit() {
    if (
      /(^1[3|4|5|7|8]\d{9}$)|(^09\d{8}$)/.test(this.state.telphone) && (
        (this.state.password && this.state.type === 1) ||
        (this.state.verifyCode && this.state.type === 2)
      )
    ) {
      return true;
    } else {
      return false;
    }
  }
  render() {
    var { home } = this.props;
    return (
      <DocumentTitle title={Utils.title()}>
        <div className="page-login">
          <div className="container page-demo">
            <p className="page-title">用户登录</p>
            <div className="demo-form">
              <p className="from-title">手机号码：</p>
              <input
                type="text"
                placeholder="填写手机号码"
                ref="telphone"
                onChange={(e) => {
                  this.setState({ telphone: e.target.value });
                }}
              />
              {/* 密码登录 */}
              {this.state.type === 1 ? (
                <>
                  <p className="from-title">输入密码：</p>
                  <input
                    type="password"
                    placeholder="填写密码"
                    ref="password"
                    onChange={(e) => {
                      this.setState({ password: e.target.value });
                    }}
                  />
                </>
              ) : null}
              {/* 手机短信登录 */}
              {this.state.type === 2 ? (
                <VerifyCode
                  getVerifyCode={(val) => {
                    this.setState({ verifyCode: val });
                  }}
                  telphone={this.state.telphone}
                />
              ) : null}
              <div className="other-type">
                {this.state.type === 1 ? (
                  <a
                    onClick={() => {
                      this.setState({ type: 2 });
                    }}
                  >
                    短信验证码登录 ›
                  </a>
                ) : null}
                {this.state.type === 2 ? (
                  <a
                    onClick={() => {
                      this.setState({ type: 1 });
                    }}
                  >
                    密码登录 ›
                  </a>
                ) : null}
              </div>
              <label className="rights">
                <input
                  type="checkbox"
                  ref="checkbox"
                  defaultChecked={true}
                  onChange={(e) => {
                    this.setState({ rights: e.target.checked });
                  }}
                />
                <span>阅读并接受</span>{" "}
                <a href="/help/terms">《THEIA用户协议》</a>
              </label>
              {this.checkCanSubmit() ? (
                <div
                  className="btn-sub-demo"
                  onClick={this.onSubmit.bind(this)}
                >
                  登录
                </div>
              ) : (
                <div className="btn-sub-demo grey">登录</div>
              )}
              <hr className="fromto" />
              <div
                className="btn-register-demo"
                onClick={() => {
                  this.props.history.push("/register");
                }}
              >
                注册帐号
              </div>
              <a href="/" className="goback">
                返回首页 ›
              </a>
            </div>
            <Nav />
            <div className="privacy">© 蝙蝠工场 2019</div>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

export default connect(
  (state) => {
    return {
      home: state.home,
    };
  },
  (dispatch) => {
    return {
      toastr: (msg) => {
        dispatch(homeActions.toastr(msg));
      },
      getUserInfo: () => {
        dispatch(homeActions.getUserInfo());
      },
    };
  }
)(withRouter(Index));
