import React, {
  Component
} from 'react';
import {
  connect
} from 'react-redux';
import {
  Link
} from 'react-router';
import {withRouter} from "react-router-dom";
import techImg from "../../../assets/img/index/tech.png"

require('./style.scss');

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }
  render() {
    return (
        <div className="child-cont-2">
            <p className="main-title">技术能力</p>
            <p className="second-title">AI训练平台：业内领先的架构设计</p>
            <img src={techImg} />
        </div>
    );
  }
}


export default connect(state => {
  return {
    home: state.home
  };
}, dispatch => {
  return {};
})(withRouter(Index));
