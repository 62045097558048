import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router";
import { withRouter } from "react-router-dom";

require("./style.scss");

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="free-week">
        <p className="main-title">忒伊亚的能力</p>
        <p className="second-title">多种技能的垂直落地</p>
        <div
          className="btn-demo"
          onClick={() => this.props.history.push("/features/ai")}
        >
          点击了解
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => {
    return {
      home: state.home,
    };
  },
  (dispatch) => {
    return {};
  }
)(withRouter(Index));
