import Component from '../containers/help/Index';

export default {
  path: '/help/',
  component: Component,
  routes: [
    {
      path: '/help/contact',
      component: Component
    },
    {
      path: '/help/qa',
      component: Component
    },
    {
      path: '/help/terms',
      component: Component
    }
  ]
}