import React, { Component } from 'react';
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import bannerMobile from "../../../assets/img/banner-mobile.png"

require('./style.scss');

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { bannerIndex: 0 };
  }

  render () {
    const {home} = this.props;
    return (
      <div className="banner-module">
        <img src={bannerMobile} />
      </div>
    );
  }
}

export default connect(state => {
  return {
    home: state.home
  };
}, dispatch => {
  return {
  };
})(withRouter(Header));
