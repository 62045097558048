import React, { Component } from 'react';
import { connect } from 'react-redux';
import constants from '../../../constants/options';
import Utils from '../../../services/utils';

import * as homeActions from '../../../containers/home/actions';

require('./style.scss');

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentDidMount() {
    this.props.getAdminOrderList();
    // console.log(this.state.lesson);
  }
  render() {
    let { home } = this.props;
    if (!home.userInfo.bdData) {
      return null;
    }
    return (
      <div className="user-order-admin">
        <table>
          <thead>
            <tr><td>订单号</td><td>学生姓名</td><td>联系方式</td><td>报名内容</td><td>报名时间</td><td>状态</td></tr>
          </thead>
          <tbody>
            {home.userInfo.bdData.inviteOrders ? home.userInfo.bdData.inviteOrders.map((item, idx) => {
              return (
                <tr key={idx}>
                  <td>{item.order_id}</td>
                  <td>{item.u_name || '-'}</td>
                  <td>{item.telphone}</td>
                  <td>{Utils.getLesson(item.commodity_id).category}-{Utils.getLesson(item.commodity_id).title}</td>
                  <td>{item.create_time ? Utils.formatDatetime(item.create_time) : ''}</td>
                  <td>{item.status || ''}</td>
                </tr>
              );})
              : null
            }
          </tbody>
        </table>
        {!home.userInfo.bdData
        || !home.userInfo.bdData.inviteOrders
        || !home.userInfo.bdData.inviteOrders.length ?
          <p className="nodata">暂无报名记录</p>
          : null
        }
        <p className="notice">该部分展示您所推广（通过您的邀请码进入）的学生报名情况，我们会及时跟进并进行处理，也辛苦老师继续跟进，学员确认学习并缴纳学费后，公司会将提成下发给您，感谢您的支持和帮助！</p>
      </div>
    ); 
  }
}

export default connect(state => {
  return {
    home: state.home
  };
}, dispatch => {
  return {
    toastr: (msg) => {
      dispatch(homeActions.toastr(msg));
    },
    getAdminOrderList: () => {
      dispatch(homeActions.getAdminOrderList());
    }
  };
})(Index);
