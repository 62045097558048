import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';

import Utils from '../../services/utils';
import Training from '../../components/feature/training';
import AI from '../../components/feature/ai'
import Toc from '../../components/feature/toc'
import Tob from '../../components/feature/tob'
require('./style.scss');

class Index extends Component {
  componentDidMount() {
  }

  render() {
    let type = this.props.location.pathname ? this.props.location.pathname.split('/')[2] : '';
    return (
      <DocumentTitle title={Utils.title()}>
        <div className="container page-feature">
          {type === 'ai' ? <AI/> : null}
          {type === 'toc' ? <Toc/> : null}
          {type === 'tob' ? <Tob/> : null}
          {type === 'training' ? <Training/> : null}
        </div>
      </DocumentTitle>
    );
  }
}

export default connect(state => {
  return {
    home: state.home
  };
}, dispatch => {
  return {
  };
})(Index);
