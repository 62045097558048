import React, {
  Component
} from 'react';
import {
  connect
} from 'react-redux';
import {
  Link
} from 'react-router';
import {withRouter} from "react-router-dom";

require('./style.scss');

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }
  render() {
    return (
        <div className="child-cont-3">
            <p className="main-title">关于忒伊亚</p>
            <p className="second-title">由一线互联网公司技术大牛组成的专业TEAM</p>
            <ul>
                <li onClick={()=>this.props.history.push("/solutions/company")}>
                    <div className="img-photo cont-1-img2"><div className="grey-box">公司简介</div></div>
                    <div className="right-text">
                        <p className="content">公司涵盖多方面业务针对于技术场景提供支持</p>
                    </div>
                </li>
                <li onClick={()=>this.props.history.push("/solutions/corp")}>
                    <div className="img-photo cont-1-img1"><div className="grey-box">企业动态</div></div>
                    <div className="right-text">
                        <p className="content">来自互联网一线企业的技术专家具备全面的技术能力</p>
                    </div>
                </li>
                <li onClick={()=>this.props.history.push("/solutions/corp")}>
                    <div className="img-photo cont-1-img3"><div className="grey-box">合作企业</div></div>
                    <div className="right-text">
                        <p className="content">知名企业共同合作支持AI时代的技术变革</p>
                    </div>
                </li>
            </ul>
        </div>
    );
  }
}


export default connect(state => {
  return {
    home: state.home
  };
}, dispatch => {
  return {};
})(withRouter(Index));
