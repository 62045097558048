import React, { Component } from "react";
import { connect } from "react-redux";
import DocumentTitle from "react-document-title";
import { withRouter } from "react-router-dom";

import Utils from "../../services/utils";
import Nav from "../../components/global/nav/Index";

require("./style.scss");

class Index extends Component {
  componentDidMount() {}

  render() {
    var { home } = this.props;
    return (
      <DocumentTitle title={Utils.title()}>
        <div className="container page-lesson-list">
          <div className="components-lesson-list child-lesson-list">
            <div className="selection-1">
              <p className="cate-title">护航计划</p>
              <div
                className="mentor lesson-li"
                onClick={() => this.props.history.push("/features/mentor")}
              >
                <p>BAT私人导师计划</p>
              </div>
              <div
                className="mentor lesson-li"
                onClick={() => this.props.history.push("/features/interview")}
              >
                <p>求职考试模拟&知识体系测评</p>
              </div>
              <p className="cate-title">校招课程项目</p>
              <div
                className="fe lesson-li"
                onClick={() => this.props.history.push("/features/fe")}
              >
                <p>前端开发工程师</p>
              </div>
              <div
                className="rd lesson-li"
                onClick={() => this.props.history.push("/features/rd")}
              >
                <p>后端开发工程师</p>
              </div>
              <p className="cate-title">服务端专向项目</p>
              <div
                className="java lesson-li"
                onClick={() => this.props.history.push("/features/java")}
              >
                <p>Java专向开发</p>
              </div>
              <div
                className="php lesson-li"
                onClick={() => this.props.history.push("/features/php")}
              >
                <p>php专向开发</p>
              </div>
              <p className="cate-title">前端专向项目</p>
              <div
                className="react lesson-li"
                onClick={() => this.props.history.push("/features/react")}
              >
                <p>前端框架-REACT</p>
              </div>
              <div
                className="vue lesson-li"
                onClick={() => this.props.history.push("/features/vue")}
              >
                <p>前端框架-VUE</p>
              </div>
            </div>
          </div>
          <Nav />
          <div className="privacy">© 蝙蝠工场 2019</div>
        </div>
      </DocumentTitle>
    );
  }
}

export default connect(
  (state) => {
    return {
      home: state.home,
    };
  },
  (dispatch) => {
    return {};
  }
)(withRouter(Index));
