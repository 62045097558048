import cookie from 'react-cookie';
import ReactGA from 'react-ga';

import * as actions from '../containers/home/actions';

const getUrlKey = function (name) {
  return decodeURIComponent((new RegExp('[?|&]'+name+'='+'([^&;]+?)(&|#|;|$)').exec(window.location.href)||[,""])[1].replace(/\+/g,'%20'))||null;
 }

const debounce = function (fn, delay) {
  var timer = null;
  return function () {
    var self = this,
      args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(self, args);
    }, delay);
  };
};

const throttle = function (fn, threhhold) {
  threhhold = threhhold || 300;
  var last, deferTimer;

  return function () {
    var self = this;
    var args = arguments;
    var now = new Date().getTime();

    if (last && now < last + threhhold) {
      clearTimeout(deferTimer);
      deferTimer = setTimeout(function () {
        last = now;
        fn.apply(self, args);
      }, threhhold);
    } else {
      last = now;
      fn.apply(self, args);
    }
  };
};

const ensureProps = function (obj, propChain) {
  var current = obj || {};
  var props = propChain.split('.');
  var chain = [];
  var prop;
  while (prop = props.shift()) {
    chain.push(prop);
    current[prop] = current[prop] || {};
    if (typeof current[prop] !== 'object') {
      // TODO: data type error track
      return false;
    }
    current = current[prop];
  }
  return true;
};

const formatAvatar = function (item) {
  return item.avatarHead + item.avatar;
};

const formatPosition = function (item) {
  var position = '--';

  /* not show top department name
  if ($.trim(item.deptDescr1)) {
      position = item.deptDescr1;
  }
  */

  if ((item.deptDescr2 || '').trim()) {
    position = item.deptDescr2;
  }
  if ((item.deptDescr3 || '').trim()) {
    position += '-' + item.deptDescr3;
  }
  if ((item.deptDescr4 || '').trim()) {
    position += '-' + item.deptDescr4;
  }
  if ((item.deptDescr5 || '').trim()) {
    position += '-' + item.deptDescr5;
  }
  return position;
};

const decodeHtml = function (src) {
  var textArea = document.createElement('div');
  textArea.innerHTML = src;
  return textArea.innerText.trim();
};

const report = function (err) {
  // TODO: Error tracking
};

const stringifyTime = function (time) {
  var time = time || '';
  // var newtime = time.replace(new RegExp("-","g"),'/');
  // var timeStamp = new Date(newtime).getTime();
  var nowTimeStamp = new Date().getTime();
  var diffSeconds = parseInt((nowTimeStamp - parseInt(time)) / 1000);
  var s = diffSeconds > 0 ? diffSeconds : 0;
  var min = 0,
    h = 0,
    d = 0,
    mon = 0;
  if (s < 60) {
    //return s + "秒前";
    return '刚刚';
  }
  if (s < 3600) {
    return parseInt(s / 60) + "分钟前";
  }
  if (s < 24 * 3600) {
    return parseInt(s / 3600) + "小时前";
  }
  var date = new Date(time);
  var mon = panNumber(date.getMonth() + 1);
  var d = panNumber(date.getDate());
  return mon + "." + d;
};

const formatDatetime = function (time, seperator) {
  var date = new Date(time);
  return formatDate(time, seperator) + ' ' + formatTime(time);
};

const title = function (current) {
  return 'THEIA-忒伊亚';
};

const formatDate = (time, seperator) => {
  var date = new Date(time);
  seperator = seperator || '-';
  return date.getFullYear() + seperator +
    panNumber(date.getMonth() + 1) + seperator +
    panNumber(date.getDate());
}

const formatTime = (time) => {
  var date = new Date(time);
  return panNumber(date.getHours()) + ':' +
    panNumber(date.getMinutes()) + ':' +
    panNumber(date.getSeconds());
}

const panNumber = (num) => {
  return num < 10 ? ('0' + num) : num;
}
const identifyURL = function (src) {
  var textArea = document.createElement('textarea');
  textArea.innerHTML = src;
  var content = textArea.value;
  var reg = /(src="|href=")?(http(s?):\/\/)((\w|=|\?|\.|\/|&|-|#|\%)+)(<\/a>)?/g;
  var url = content.replace(reg, function (word) {
    if (word.indexOf('</a>') > -1 || word.indexOf('src="') > -1 || word.indexOf('href="') > -1) {
      return word;
    } else {
      return '<a href="' + word + '" target="_blank">' + word + '</a>';
    }
  });
  return url;
};

const toBottom = function (className) {
  var main = document.getElementsByClassName(className)[0];
  var viewH = main.clientHeight;
  var contentH = main.scrollHeight;
  main.scrollTop = contentH - viewH;
};

const setCookie = (name,value) => {  
  var Days = 30;  
  var exp  = new Date();  
  exp.setTime(exp.getTime() + Days*24*60*60*1000);  
  document.cookie = name + "="+ escape (value) + ";expires=" + exp.toGMTString();  
}

const getCookie = (name) => {  
  var arr = document.cookie.match(new RegExp("(^| )"+name+"=([^;]*)(;|$)"));  
  if(arr != null){  
    return (arr[2]);  
  }else{  
    return "";  
  }  
}

export default {
  title,
  debounce,
  throttle,
  ensureProps,
  formatAvatar,
  formatPosition,
  decodeHtml,
  report,
  stringifyTime,
  formatDate,
  formatDatetime,
  identifyURL,
  toBottom,
  getUrlKey,
  setCookie,
  getCookie,
}