import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

require("./style.scss");

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: this.props.pathname,
    };
  }
  componentDidMount() {}

  render() {
    var { home } = this.props;
    return (
      <div className="help-banner">
        <p>帮助与支持</p>
        <ul className="nav">
          <li className={this.props.pathname == "model" ? "active" : null}>
            <Link to="/help/model">
              <span>模型及应用</span>
            </Link>
          </li>
          <li className={this.props.pathname == "contact" ? "active" : null}>
            <Link to="/help/contact">
              <span>联系我们</span>
            </Link>
          </li>
        </ul>
      </div>
    );
  }
}

export default connect(
  (state) => {
    return {
      home: state.home,
    };
  },
  (dispatch) => {
    return {};
  }
)(Index);
