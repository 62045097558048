import React, { Component } from 'react';
import { connect } from 'react-redux';
import trainngingImg from '../../assets/img/feature/training.png'
import trainnging2Img from '../../assets/img/feature/training2.png'
import trainnging3Img from '../../assets/img/feature/training3.png'
import trainnging4Img from '../../assets/img/feature/training4.png'
import trainnging5Img from '../../assets/img/feature/training5.png'
import trainngingBannerImg from '../../assets/img/feature/training-banner.jpg'

class Index extends Component {
  componentDidMount() {
  }

  render() {
    return (
      <div>
        <img src={trainngingBannerImg} />
        <article>
          <p className="main-title">企业级实训能力</p>
          <p className="second-title">企业级能力支持，一线企业行业专家，技术层面知识输出</p>
          <img src={trainnging2Img} />
          <div className='content-box'>
            <p className="content">
              基于天津算力中心的强大配置，忒伊亚在高校实训及AI教学层面拥有得天独厚的优势。我们已经联合多所高校参与学生的AI能力实训，实训通过三个阶段进行。
            </p>
            <p className="content">
              · 第一个阶段：学习阶段，该阶段中，忒伊亚贴合学校的人才培养目标，针对AI在企业级层面进行讲解。
            </p>
            <p className="content">
              · 第二个阶段：实践阶段，该阶段中有企业专家进行实际操作指导。全面围绕企业级项目进行训练。
            </p>
            <p className="content">
              · 第三个阶段：验证阶段，该阶段将会从学生的吸收程度进行评估，包括两部分，知识测试和项目成果沉淀
            </p>
          </div>
          <img src={trainngingImg} className='img-bottom'/>
          <p className="main-title">课程设计</p>
          <p className="second-title">针对企业级的大模型能力讲解</p>
          <img src={trainnging3Img} />
          <div className='content-box'>
            <p className="content">
              企业级实训跟进，模拟企业流程管理方式，从学习到训练，再到应用沉淀的一站式流程管控方法。
            </p>
          </div>
          <img src={trainnging4Img} className='img-bottom' />
          <p className="main-title">基础设施</p>
          <p className="second-title">天津高新科技园为基础的实训加成</p>
          <img src={trainnging5Img} />
        </article>
      </div>
    );
  }
}

export default connect(state => {
  return {
    home: state.home
  };
}, dispatch => {
  return {
  };
})(Index);
