import React, { Component } from 'react';
import { connect } from 'react-redux';
import logoImg from '../../../assets/img/logo2.png';
import news708Img from '../../../assets/img/solution/news_708.png';
require('./style.scss');

class Index extends Component {
  componentDidMount() {
        
  }

  render() {
    var { home } = this.props;
    return (
        <div className="components-team child-solution">
          <div className="selection-1">
            <ul className='news'>
              <li>
                <div className='left-img'><img src={news708Img} /></div>
                <div className='right-content'>
                  <p>2024年7月9日，忒伊亚随恒生智算、海飞科、青云等公司，与北京信息科技大学计算机学院对接学校实训计划</p>
                </div>
              </li>
              <li>
                <div className='left-img'></div>
                <div className='right-content'>
                  <p>2024年6月18日，忒伊亚作为高校AI实训技术支持企业，参与由天津武清区人民政府举办的京津通用人工智能产业发展创新大会暨算力对接会</p>
                </div>
              </li>
            </ul>
            <p className='title'>企业宗旨</p>
            <p className="subtitle">忒伊亚成立于2024年，在大模型及AI重点发展的时代中，应运而生。</p>
            <p className="subtitle">忒伊亚基于大量算力和数据中心的支持下，重点投入在AI赋能上，我们针对各个行业做AI赋能，主要分为两个步骤，通过数字化赋能原有行业，通过人工智能再次升级行业。</p>
            <img src={logoImg} style={{ margin: '0.3rem auto', display: 'block', width: '1rem' }}/>
          </div>
        </div>
    );
  }
}

export default connect(state => {
  return {
    home: state.home
  };
}, dispatch => {
  return {
  };
})(Index);
