import React, {
  Component
} from 'react';
import {
  connect
} from 'react-redux';
import {
  Link
} from 'react-router';
import {withRouter} from "react-router-dom";

require('./style.scss');

class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openNav: false
    };
  }
  clickOpenNav(navName) {
    if (this.state.openNav === navName) {
      this.setState({
        openNav: false
      });
    } else {
      this.setState({
        openNav: navName
      });
    }
  }
  clickToGo(link) {
    this.props.history.push(link);
    this.props.closeNav();
    this.setState({
      openNav: false
    });
  }
  render() {
    return (
      <div className="index-nav">
        <ul>
          <li onClick={this.clickOpenNav.bind(this, 'feature')}><span className="title">核心业务</span><span className="sub">AI及数字化的能力搭建</span><span className="mOrp">{this.state.openNav == 'feature' ? '-' : '+'}</span></li>
          {this.state.openNav === 'feature' ? <li onClick={()=>this.clickToGo('/features/ai')}><span className="title">AI模型应用</span></li> : null}
          {this.state.openNav === 'feature' ? <li onClick={()=>this.clickToGo('/features/toc')}><span className="title">C端能力建设</span></li> : null}
          {this.state.openNav === 'feature' ? <li onClick={()=>this.clickToGo('/features/tob')}><span className="title">企业级能力建设</span></li> : null}
          {this.state.openNav === 'feature' ? <li onClick={()=>this.clickToGo('/features/training')}><span className="title">企业级实训</span></li> : null}
          <li onClick={this.clickOpenNav.bind(this, 'solution')} className="top"><span className="title">关于忒伊亚</span><span className="sub">一线企业专家的技术团队</span><span className="mOrp">{this.state.openNav == 'solution' ? '-' : '+'}</span></li>
          {this.state.openNav === 'solution' ? <li onClick={()=>this.clickToGo('/solutions/company')}><span className="title">公司简介</span></li> : null}
          {this.state.openNav === 'solution' ? <li onClick={()=>this.clickToGo('/solutions/team')}><span className="title">团队简介</span></li> : null}
          {this.state.openNav === 'solution' ? <li onClick={()=>this.clickToGo('/solutions/crop')}><span className="title">合作企业</span></li> : null}
          <li onClick={this.clickOpenNav.bind(this, 'help')} className="top"><span className="title">帮助与支持</span><span className="sub">常见问题的解答</span><span className="mOrp">{this.state.openNav == 'help' ? '-' : '+'}</span></li>
          {this.state.openNav === 'help' ? <li onClick={()=>this.clickToGo('/help/model')}><span className="title">模型及应用</span></li> : null}
          {this.state.openNav === 'help' ? <li onClick={()=>this.clickToGo('/help/contact')}><span className="title">联系我们</span></li> : null}
          <li className="top end" onClick={()=>this.clickToGo('/join')}><span className="title">加入我们</span></li>
        </ul>
      </div>
    );
  }
}


export default connect(state => {
  return {
    home: state.home
  };
}, dispatch => {
  return {};
})(withRouter(Nav));
