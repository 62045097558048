import React, {
  Component,
  Fragment
} from 'react';
import {
  connect
} from 'react-redux';
import DocumentTitle from 'react-document-title';
import {withRouter} from "react-router-dom";

import Utils from '../../services/utils';
import Nav from '../../components/global/nav/Index';
import Request from '../../services/request';

import * as homeActions from '../home/actions';
require('./style.scss');

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      canSubmit: false,
      canNext: false,
      countTime: 0
    };
  }
  componentDidMount() {
    // console.log(this.state.lesson);
  }
  onSubmit() {
    let dataSend = {
      telphone: this.refs.telphone.value,
      password: this.refs.password.value,
      password2: this.refs.password2.value,
      verifycode: this.refs.verifycode.value
    };
    Request('/api/user/register', {
      method: 'POST',
      headers: {
        "Content-type": 'application/json'
      },
      body: JSON.stringify(dataSend)
    })
    .then(data => {
      if (data.code == 0) {
        this.props.history.push('/');
      } else {
        alert('提交失败，请稍后重试！');
      }
    });
  }
  // onSubmit() {
  //   let dataSend = {
  //     password: this.refs.password.value,
  //     password2: this.refs.password2.value
  //   };
  //   Request('/api/user/changePassword', {
  //     method: 'POST',
  //     headers: {
  //       "Content-type": 'application/json'
  //     },
  //     body: JSON.stringify(dataSend)
  //   })
  //   .then(data => {
  //     if (data.code == 0) {
  //       this.setState({
  //         step: 3
  //       });
  //     } else {
  //       alert('提交失败，请稍后重试！');
  //     }
  //   });
  // }
  onVerify() {
    let dataSend = {
      telphone: this.refs.telphone.value,
      verifycode: this.refs.verifycode.value
    };
    Request('/api/user/verifyvc', {
      method: 'POST',
      headers: {
        "Content-type": 'application/json'
      },
      body: JSON.stringify(dataSend)
    }).then(data => {
      if (data.code == 0) {
        this.setState({
          step: 2
        });
      } else {
        this.setState({
          step: 1
        });
      }
    });
  }
  onType2() {
    let data = {
      password: this.refs.password.value,
      password2: this.refs.password2.value
    };
    if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,16}$/.test(data.password) &&
      data.password === data.password2
    ) {
      this.setState({
        canSubmit: true
      });
    } else {
      this.setState({
        canSubmit: false
      });
    }
  }
  verifyPhone() {
    let ct = 60;
    if (!/(^1[3|4|5|7|8]\d{9}$)|(^09\d{8}$)/.test(this.refs.telphone.value)) {
      return false;
    }
    this.doGetVerify(this.refs.telphone.value);
    this.setState({
      countTime: 60
    });
    let countTimeInt = setInterval(() => {
      // console.log(this.state.countTime);
      if (ct == 0) {
        clearInterval(countTimeInt);
        return false;
      }
      ct--;
      this.setState({
        countTime: ct
      });
    }, 1000);
  }
  doGetVerify(phone) {
    Request('/api/sendvc?phone=' + phone)
      .then(data => {
        console.log(`send msm to ${phone}`);
        this.setState({
          canNext: true
        });
      }, (err) => {});
  }
  render() {
    var { home } = this.props;
    return (
        <DocumentTitle title={Utils.title()}>
            <div>
                <div className="container page-demo">
                    <p className="page-title">密码重置</p>
                    <div className="demo-form">
                      {this.state.step === 1 ?
                        <Fragment>
                          <p className="from-title">手机号码：</p>
                          <input type="text" placeholder='填写手机号码' ref="telphone" onInput={this.onType.bind(this)}/>
                          <div className="verify-box">
                              <input className="verifycode" type="text" placeholder='填写验证码' ref="verifycode" onInput={this.onType.bind(this)}/>
                              {this.state.countTime == 0 ?
                                  <div className="verifybtnactive" onClick={this.verifyPhone.bind(this)}>发送验证码</div> :
                                  <div className="verifybtn">{this.state.countTime}s后重新获取</div>
                              }
                          </div>
                          {this.state.canNext?
                              <div className="btn-sub-demo" onClick={this.onSubmit.bind(this)}>提交验证</div> : 
                              <div className="btn-sub-demo grey">提交验证</div>
                          }
                        </Fragment> : null
                      }
                      {this.state.step === 2 ?
                        <Fragment>
                          <p className="from-title">设置密码：</p>
                          <input type="password" placeholder='包含数字、大写字母和小写字母' ref="password" onInput={this.onType2.bind(this)}/>
                          <p className="from-title">确认密码：</p>
                          <input type="password" placeholder="再次输入密码" ref="password2" onInput={this.onType2.bind(this)}/>
                          <label className="rights">
                              <input type="checkbox" ref="checkbox" defaultChecked={true} onChange={this.onType2.bind(this)}/> 
                              <span>阅读并接受</span> <a href="/help/terms">《THEIA用户协议》</a>
                          </label>
                          {this.state.canSubmit?
                              <div className="btn-sub-demo" onClick={this.onSubmit.bind(this)}>提交修改</div> : 
                              <div className="btn-sub-demo grey">提交修改</div>
                          }
                        </Fragment> : null
                      }
                      {this.state.step === 3 ?
                        <Fragment>
                          <p className="code-success">密码重置成功，请重新登录！5秒后跳转！<br/><a href="/login">点击重新登录</a></p>
                        </Fragment> : null
                      }
                    </div>
                    <Nav />
                    <div className="privacy">© 蝙蝠工场 2018</div>
                </div>
            </div>
        </DocumentTitle>
    );
  }
}

export default connect(state => {
  return {
    home: state.home
  };
}, dispatch => {
  return {};
})(withRouter(Index));
