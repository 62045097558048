import Utils from '../../services/utils';
import Request from '../../services/request';

export const CONSTANTS = {
  GET_USER_INFO: 'GET_USER_INFO',
  GET_USER_ORDER: 'GET_USER_ORDER',
  GET_USER_Admin_ORDER: 'GET_USER_Admin_ORDER',
  GET_LANGUAGE: 'GET_LANGUAGE',
  SERVER_ERROR: 'SERVER_ERROR',
  SHOW_ALERT: 'SHOW_ALERT',
  HIDE_ALERT: 'HIDE_ALERT',
  SHOW_TOASTR: 'SHOW_TOASTR',
  HIDE_TOASTR: 'HIDE_TOASTR',
  SHOW_LOADING: 'SHOW_LOADING',
  HIDE_LOADING: 'HIDE_LOADING'
};

export function loading(msg) {
  return dispatch => {
    setTimeout(() => {
      dispatch({
        type: CONSTANTS.SHOW_LOADING,
        data: msg
      });
    }, 0);
  };
}

export function hideLoading() {
  return {
    type: CONSTANTS.HIDE_LOADING
  };
}

export function toastr(msg) {
  return dispatch => {
    setTimeout(() => {
      setTimeout(() => {
        dispatch({
          type: CONSTANTS.HIDE_TOASTR
        });
      }, 3 * 1000);
      dispatch({
        type: CONSTANTS.SHOW_TOASTR,
        data: msg
      });
    }, 0);
  };
}

export function hideToastr() {
  return {
    type: CONSTANTS.HIDE_TOASTR
  };
}

export function alert(title, msg) {
  if (!msg) {
    msg = title;
    title = '提示';
  }
  return {
    type: CONSTANTS.SHOW_ALERT,
    data: {
      title: title,
      msg: msg || '--'
    }
  };
}

export function hideAlert() {
  return {
    type: CONSTANTS.HIDE_ALERT
  };
}

export function getUserInfo() {
  return (dispatch) => {
    Request('/api/users/getInfo')
      .then(data => {
        dispatch({
          type: CONSTANTS.GET_USER_INFO,
          data: data
        });
      }, (err) => {
        Utils.report(err);
        dispatch({
          type: CONSTANTS.SERVER_ERROR,
          data: err
        });
      });
  };
}

export function getOrderList() {
  return (dispatch) => {
    Request('/api/orders/getOrderList?page=0&page_size=100')
      .then(data => {
        dispatch({
          type: CONSTANTS.GET_USER_ORDER,
          data: data
        });
      }, (err) => {
        Utils.report(err);
        dispatch({
          type: CONSTANTS.SERVER_ERROR,
          data: err
        });
      });
  };
}

export function getAdminOrderList() {
  return (dispatch) => {
    Request('/api/orders/getAdminOrderList?page=0&page_size=100')
      .then(data => {
        dispatch({
          type: CONSTANTS.GET_USER_Admin_ORDER,
          data: data
        });
      }, (err) => {
        Utils.report(err);
        dispatch({
          type: CONSTANTS.SERVER_ERROR,
          data: err
        });
      });
  };
}

export function getLanguage(id) {
  return (dispatch) => {
    Request('/cdn/static/m-official/' + id + '.json')
      .then(data => {
        data.langName = id;
        dispatch({
          type: CONSTANTS.GET_LANGUAGE,
          data: data
        });
      }, (err) => {
        Utils.report(err);
        dispatch({
          type: CONSTANTS.SERVER_ERROR,
          data: err
        });
        dispatch(toastr('Language Load Error！\n Please Refresh This Page!'));
      });
  };
}
