import React, {
  Component
} from 'react';
import { Link } from 'react-router-dom'
import {
  connect
} from 'react-redux';
import ReactGA from 'react-ga';
import {withRouter} from "react-router-dom";

import Nav from '../../../components/global/nav/Index';
import Utils from '../../../services/utils'

require('./style.scss');

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openNav: false
    };
    Utils.setCookie('inviteCode', Utils.getUrlKey('inviteCode'))
  }
  clickNav(navName) {
    if (navName === this.state.openNav) {
      this.setState({
        openNav: false
      });
    } else {
      this.setState({
        openNav: navName
      });
    }
  }
  closeNav () {
    this.setState({
      openNav: false
    });
  }
  render () {
    var { home } = this.props;
    return (
      <div className="container">
        {this.state.openNav ? <div className="popup-back" onClick={(e)=>{
          e.preventDefault();
          e.stopPropagation();
          this.setState({ openNav: false });
        }}></div> 
        : null }
        <div className={this.props.white ? "header-module white" : "header-module"}>
          <a className="menu" onClick={this.clickNav.bind(this, 'menu')}>
              <div className="menu-logo"></div>
          </a>
          <div className="logo" onClick={()=>this.props.history.push("/")}></div>
          {this.state.openNav === 'menu' ? <div className="menu-options animate"><Nav closeNav={this.closeNav.bind(this)} /></div> : null}
          {this.state.openNav === 'lang' ? 
            <div className="lang-options animate">
                <div className="index-nav">
                    <ul>
                        <li className="end" onClick={()=>this.props.history.push('/?lang=en')}><span className="title">English</span></li>
                        <li className="end" onClick={()=>this.props.history.push('/')}><span className="title">中文</span></li>
                    </ul>
                </div>
            </div>
            : null
          }
          <div className='lang'></div>
          {/* {home && home.userInfo && home.userInfo.uid ? 
            <Link to="/user/prof" className="lang" id="userLogin">
                <span>{home.userInfo.u_name || `BatMan${home.userInfo.uid}`}</span>
                <span className="lang-logo"></span>
            </Link>
            : <Link to="/login" className="lang" id="userLogin">
                <span>登录</span>
                <span className="lang-logo"></span>
            </Link>
          } */}
        </div>
      </div>
    );
  }
}

export default connect(state => {
  return {
    home: state.home
  };
}, dispatch => {
  return {
    dispatch: dispatch
  };
})(withRouter(Header));
