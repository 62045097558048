import React, {
  Component
} from 'react';
import Modal from 'react-modal';

require('./style.scss');

export default class IToastr extends Component {
  render() {
    const customStyles = {
      overlay: {
        position: 'fixed',
        left: 'auto',
        right: 'auto',
        bottom: 'auto',
        top: 'auto',
        left: '50%',
        top: '50%'
      },
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        width: '1.69rem',
        padding: '0.15rem 0.2rem',
        bottom: 'auto',
        marginRight: '-50%',
        background: 'rgba(51,51,51,0.54)',
        transform: 'translate(-50%, -50%)'
      }
    };

    var {
      content,
      isOpen
    } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        contentLabel="Toastr"
        style={customStyles}>
        <div className="itoastr-module">
          <div className="itoastr-body" ref="content">
            {content}
          </div>
        </div>
      </Modal>
    );
  }
}
