import React, { Component } from 'react';
import { connect } from 'react-redux';
import logoImg from '../../../assets/img/logo2.png';
require('./style.scss');

class Index extends Component {
  componentDidMount() {
        
  }

  render() {
    var { home } = this.props;
    return (
        <div className="components-comp child-solution">
          <div className="selection-1">
            <p className="subtitle">忒伊亚的产研团队由来自 BAT 等互联网一线企业的成员组成，他们具备全面的技术能力，包括产品设计、UI 设计、服务端开发、前端开发、客户端开发、算法和 AI 开发等。团队的核心成员还曾在一线 AI 公司参与数字人项目的研发，并成功开发了多个 TOC 和 TOB 项目，且已投入生产应用。</p>
            <p className="subtitle">我们公司的愿景是成为行业内的领军企业，通过创新和卓越的技术，为用户带来前所未有的体验和价值。我们的使命是推动科技的发展，为社会创造积极的影响，让人们的生活更加便捷和美好。</p>
            <p className="subtitle subtitle-left">我们的团队在以下领域拥有专业能力：</p>
            <ul className='subcontent-ul'>
              <li>AI&LLM大模型开发：推动人工智能技术的创新应用。</li>
              <li>产品设计：致力于打造用户体验卓越的产品。</li>
              <li>UI设计：创造美观、易用的界面，提升产品的视觉吸引力。</li>
              <li>服务端开发：确保后台系统的稳定性和高效性。</li>
              <li>前端开发：构建用户友好的前端界面，提供流畅的交互体验。</li>
              <li>移动端开发：为移动设备提供优质的应用程序。</li>
            </ul>
            <p className="subtitle">通过不断创新和努力，我们致力于实现公司的愿景和使命，为客户提供高质量的产品和解决方案，助力企业实现数字化转型和业务增长。</p>
            <img src={logoImg} style={{ margin: '0.3rem auto', display: 'block', width: '1rem' }}/>
          </div>
        </div>
    );
  }
}

export default connect(state => {
  return {
    home: state.home
  };
}, dispatch => {
  return {
  };
})(Index);
