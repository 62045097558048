import Component from '../containers/solution/Index';

export default {
  path: '/solutions/',
  component: Component,
  routes: [
    {
      path: '/solutions/team',
      component: Component
    },
    {
      path: '/solutions/corp',
      component: Component
    },
    {
      path: '/solutions/company',
      component: Component
    }
  ]
}