import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Picker, List, WhiteSpace, DatePicker } from 'antd-mobile';
import constants from '../../../constants/options';
import Utils from '../../../services/utils';
import Request from '../../../services/request';
import {withRouter} from "react-router-dom";

import * as homeActions from '../../../containers/home/actions';

require('antd-mobile/dist/antd-mobile.css');
require('./style.scss');

const sexOptions = [
  {
    label: '男',
    value: 0,
  },
  {
    label: '女',
    value: 1,
  }
];

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editskill: false,
      skillArr: [],
      sValue: 0,
      birth: '',
    };
  }
  componentDidMount() {
    const { home } = this.props;
    for (let key in home.userInfo) {
      console.log(key)
      if (this.refs[key]) {
        this.refs[key].value = home.userInfo[key];
      }
    }
    if (home.userInfo.subject) {
      this.setState({
        skillArr: home.userInfo.subject.split(',')
      });
    }
    this.setState({
      sValue: home.userInfo.sex
    });
    if (home.userInfo.brith) {
      this.setState({
        birth: new Date(home.userInfo.brith),
      });
    }
  }
  componentWillReceiveProps (nextProps) {
    if (this.props.home.userInfo !== nextProps.home.userInfo) {
      const { home } = nextProps;
      for (let key in home.userInfo) {
        console.log(key)
        if (this.refs[key]) {
          this.refs[key].value = home.userInfo[key];
        }
      }
      if (home.userInfo.subject) {
        this.setState({
          skillArr: home.userInfo.subject.split(',')
        });
      }
      this.setState({
        sValue: home.userInfo.sex
      });
      if (home.userInfo.brith) {
        this.setState({
          birth: new Date(home.userInfo.brith),
        });
      }
    }
  }
  onSubmit() {
    const { toastr, getUserInfo } = this.props;
    let dataSend = {
      u_name: this.refs.u_name.value,
      birth: this.state.birth ? this.state.birth.getTime() : '',
      sex: this.state.sValue,
      email: this.refs.email.value,
      qq: this.refs.qq.value,
      address: this.refs.address.value,
      subject: this.refs.skill.value,
    };
    Request('/api/users/saveInfo', {
      method: 'POST',
      headers: {
        "Content-type": 'application/json'
      },
      body: JSON.stringify(dataSend)
    })
    .then(data => {
      if (data.code + '' === '0') {
        toastr('信息已更新！');
        getUserInfo();
      } else {
        toastr('提交失败，请稍后重试！');
      }
    })
    .catch((err) => {
      if (err.status - 0 === 401) {
        this.props.history.push("/login");
      }
    });
  }
  render() {
    let { home } = this.props;
    let sexDesc = '';
    let skillArr = [];
    if (!home.userInfo && !home.userInfo.u_name) {
      return null;
    }
    if (home.userInfo.sex === 1) {
      sexDesc = '男';
    } else if (home.userInfo.sex === 2) {
      sexDesc = '女';
    }
    return (
      <div className="user-edit-prof">
        <div className="user-list">
          <div className="user-label">手机：</div>
          <div className="user-detail"><input type="text" ref="telphone" readOnly /></div>
        </div>
        <div className="user-list">
          <div className="user-label">用户名：</div>
          <div className="user-detail"><input type="text" ref="u_name" placeholder="请填写用户名" /></div>
        </div>
        <List className="picker-list">
          <DatePicker
            mode="date"
            value={this.state.birth}
            onChange={date => {
              this.setState({ birth: date })
            }}
            minDate={new Date(1950, 1, 1, 0, 0, 0)}
            maxDate={new Date()}
          >
            <List.Item arrow="horizontal">出生日期:</List.Item>
          </DatePicker>
        </List>
        <List className="picker-list">
          <Picker
            data={sexOptions}
            cols={1}
            title="选择性别"
            value={[this.state.sValue]}
            onChange={v => this.setState({ sValue: v[0] })}
            onOk={v => this.setState({ sValue: v[0] })}
          >
            <List.Item arrow="horizontal">性别:</List.Item>
          </Picker>
        </List>
        <div className="user-list">
          <div className="user-label">邮箱：</div>
          <div className="user-detail"><input type="email" ref="email" placeholder="请填写邮箱" /></div>
        </div>
        <div className="user-list">
          <div className="user-label">QQ：</div>
          <div className="user-detail"><input type="text" ref="qq" placeholder="请填写常用QQ号" /></div>
        </div>
        <div className="user-list">
          <div className="user-label">联系地址：</div>
          <div className="user-detail"><input type="text" ref="address" placeholder="请填写联系地址" /></div>
        </div>
        <div className="user-skill">
          <div className="user-label">技能：</div>
          <div className={this.state.editskill ? 'user-edit' : 'user-edit hide'}>
            <textarea ref="skill" placeholder="请填写擅长技能，用逗号分隔" onBlur={
              () => {
                console.log(this.refs.skill.value);
                this.setState({
                  editskill: false,
                  skillArr: this.refs.skill.value.trim().replace("，", ",").split(',')
                });
              }
            }></textarea>
          </div>
          {!this.state.editskill ?
            <div className="user-info" onClick={() => {
              // this.refs.skill.focus();
              this.refs.skill.value = this.state.skillArr.join(',');
              this.setState({editskill: true});
            }}>
              {this.state.skillArr.length ? 
                this.state.skillArr.map((item, idx) => {
                  return (<span className="data-item" key={idx}>{item}</span>);
                })
                : <span className="nodata">未填写擅长技能</span>
              }
            </div>
            : null
          }
        </div>
        <div className="btn-sub-demo" onClick={this.onSubmit.bind(this)}>提交修改</div>
      </div>
    ); 
  }
}

export default connect(state => {
  return {
    home: state.home
  };
}, dispatch => {
  return {
    getUserInfo: () => {
      dispatch(homeActions.getUserInfo());
    },
    toastr: (msg) => {
      dispatch(homeActions.toastr(msg));
    }
  };
})(withRouter(Index));
