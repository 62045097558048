import React, {
  Component
} from 'react';
import {
  connect
} from 'react-redux';
import {
  Link
} from 'react-router';
import {withRouter} from "react-router-dom";

require('./style.scss');

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }
  render() {
    return (
      <div className="child-cont-1">
        <p className="main-title">提供高质量的解决方案<br/>助力企业实现AI转型</p>
        <p className="second-title"><b>数字化</b>、<b>AI化</b>、<b>平台化</b>、<b>生态化</b> 的一站式能力建设</p>
        <ul>
          <li onClick={()=>this.props.history.push("/features/ai")}>
            <i className="cont-1-img1"></i>
            <div className="right-text">
              <p className="title">AI模型应用</p>
              <p className="content">通过AI及大模型，提供更先进的行业解决模式，赋能多个领域！</p>
            </div>
          </li>
          <li onClick={()=>this.props.history.push("/features/toc")}>
            <i className="cont-1-img2"></i>
            <div className="right-text">
              <p className="title">C端能力建设</p>
              <p className="content">对C端用户产出各类产品，解决用户核心诉求，扎根垂直方向。</p>
            </div>
          </li>
          <li onClick={()=>this.props.history.push("/features/tob")}>
            <i className="cont-1-img3"></i>
            <div className="right-text">
              <p className="title">企业级能力建设</p>
              <p className="content">针对企业开发更专业的平台，解决企业效能及推进等各类问题。</p>
            </div>
          </li>
          <li>
            <i className="cont-1-img4"></i>
            <div className="right-text">
              <p className="title">基础技术研发</p>
              <p className="content">多技术方向专业知识体系，提供各基础能力的支持及研发。</p>
            </div>
          </li>
          <li onClick={()=>this.props.history.push("/features/training")}>
            <i className="cont-1-img5"></i>
            <div className="right-text">
              <p className="title">企业级实训能力</p>
              <p className="content">企业级能力支持，一线企业行业专家，技术层面知识输出。</p>
            </div>
          </li>
        </ul>
      </div>
    );  
  }
}


export default connect(state => {
  return {
    home: state.home
  };
}, dispatch => {
  return {};
})(withRouter(Index));
