import React, { Component } from 'react';
import { connect } from 'react-redux';
import aiBannerImg from '../../assets/img/feature/ai-banner.png'
import techImg from '../../assets/img/index/tech.png'
import ai1Img from '../../assets/img/index/ai1.png'
import processImg from '../../assets/img/feature/process.png'
import coachDemoImg from '../../assets/img/feature/coach-demo.gif'

class Index extends Component {
  componentDidMount() {
  }

  render() {
    return (
      <div className="container page-feature">
        <img src={aiBannerImg} />
        <article>
          <p className="main-title">AI模型应用</p>
          <p className="second-title">通过AI及大模型提供先进的行业解决模式，赋能多个领域</p>
          <img src={coachDemoImg} style={{margin: '0 auto', width: '2rem', display: 'block'}} />
          <div className='content-box'>
            <p className="content">
              忒伊亚针对不同行业诉求，提供了各类AI解决方案，包括数字人AI解决方案、高校教培AI解决方案、招聘AI解决方案等。
            </p>
          </div>
          <img src={ai1Img} />
          <div className='content-box'>
            <p className="content">
              基于基础设施及忒伊亚自研模型平台，我们针对不同行业定向提供解决方案和积累更多垂直领域的模型。在训练过程中将使用更小的代价实现行业诉求。同时针对行业诉求提供更上层的应用能力，提供用户可使用的软件能力。
            </p>
          </div>
          <p className="main-title">AI架构模式</p>
          <p className="second-title">业内领先的架构设计</p>
          <img src={techImg} className='img-bottom' />
          <p className="main-title">落地流程</p>
          <p className="second-title">业内领先的架构设计</p>
          <img src={processImg} />
        </article>
      </div>
    );
  }
}

export default connect(state => {
  return {
    home: state.home
  };
}, dispatch => {
  return {
  };
})(Index);