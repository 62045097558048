import React, { Component } from "react";
import { connect } from "react-redux";
import DocumentTitle from "react-document-title";
import { withRouter } from "react-router-dom";

import Utils from "../../services/utils";
import Nav from "../../components/global/nav/Index";
import Banner from "../../components/help/banner/Index";
import Model from "../../components/help/model/Index";
import Contact from "../../components/help/contact/Index";

require("./style.scss");

class Index extends Component {
  componentDidMount() {}

  render() {
    var { home } = this.props;
    return (
      <DocumentTitle title={Utils.title()}>
        <div>
          <div className="container page-help">
            <Banner pathname={this.props.location.pathname.split("/")[2]} />
            {this.props.location.pathname.split("/")[2] === "model" ? (
              <Model />
            ) : null}
            {this.props.location.pathname.split("/")[2] === "contact" ? (
              <Contact />
            ) : null}
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

export default connect(
  (state) => {
    return {
      home: state.home,
    };
  },
  (dispatch) => {
    return {};
  }
)(withRouter(Index));
