import React, { Component } from "react";
import Request from "../../services/request";
import { connect } from "react-redux";
import * as homeActions from "../../containers/home/actions";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countTime: 0,
    };
  }
  componentDidMount() {
    // console.log(this.state.lesson);
  }
  onType(e) {
    this.props.getVerifyCode(e.target.value)
  }
  verifyPhone() {
    let ct = 60;
    if (!/(^1[3|4|5|7|8]\d{9}$)|(^09\d{8}$)/.test(this.props.telphone)) {
      this.props.toastr('请输入正确手机号！');
      return false;
    }
    this.doGetVerify(this.props.telphone);
    this.setState({
      countTime: 60,
    });
    let countTimeInt = setInterval(() => {
      // console.log(this.state.countTime);
      if (ct == 0) {
        clearInterval(countTimeInt);
        return false;
      }
      ct--;
      this.setState({
        countTime: ct,
      });
    }, 1000);
  }
  doGetVerify(phone) {
    Request("/api/sendvc?phone=" + phone).then(
      (data) => {
        console.log(`send msm to ${phone}`);
      },
      (err) => {}
    );
  }
  render() {
    var { home } = this.props;
    return (
      <div className="verify-box">
        <input
          className="verifycode"
          type="text"
          placeholder="填写验证码"
          ref="verifycode"
          onChange={this.onType.bind(this)}
        />
        {this.state.countTime == 0 ? (
          <div
            className="verifybtnactive"
            onClick={this.verifyPhone.bind(this)}
          >
            发送验证码
          </div>
        ) : (
          <div className="verifybtn">{this.state.countTime}s后重新获取</div>
        )}
      </div>
    );
  }
}

export default connect(state => {
  return {
    home: state.home
  };
}, dispatch => {
  return {
    toastr: (msg) => {
      dispatch(homeActions.toastr(msg));
    }
  };
})(Index);
