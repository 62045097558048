import React, { Component } from "react";
import { connect } from "react-redux";
import DocumentTitle from "react-document-title";
import { withRouter } from "react-router-dom";

import Utils from "../../services/utils";
import Banner from "../../components/solution/banner/Index";
import Nav from "../../components/global/nav/Index";
import Company from "../../components/solution/company/Index";
import Team from "../../components/solution/team/Index";
import Corp from "../../components/solution/corp/Index";

import * as homeActions from "../home/actions";

require("./style.scss");

class Index extends Component {
  componentDidMount() {}
  render() {
    var { home } = this.props;
    return (
      <DocumentTitle title={Utils.title()}>
        <div>
          <div className="container page-solution">
            <Banner pathname={this.props.location.pathname.split("/")[2]} />
            {this.props.location.pathname.split("/")[2] == "company" ? (
              <Company />
            ) : null}
            {this.props.location.pathname.split("/")[2] == "team" ? (
              <Team />
            ) : null}
            {this.props.location.pathname.split("/")[2] == "corp" ? (
              <Corp />
            ) : null}
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

export default connect(
  (state) => {
    return {
      home: state.home,
    };
  },
  (dispatch) => {
    return {};
  }
)(withRouter(Index));
