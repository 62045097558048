import React, { Component } from 'react';
import Modal from 'react-modal';

require('./style.scss');

export default class IModal extends Component {
    constructor() {
        super();
        this.state = { isOpen: false };
        this.close = this.close.bind(this);
    }
    render() {
        const customStyles = {
            overlay : {
                position          : 'fixed',
                top               : 0,
                left              : 0,
                right             : 0,
                bottom            : 0,
                backgroundColor   : 'rgba(74,74,74,0.61)'
            },
            content : {
                top                   : '50%',
                left                  : '50%',
                right                 : 'auto',
                width                 : '62%',
                bottom                : 'auto',
                marginRight           : '-50%',
                transform             : 'translate(-50%, -50%)'
            }
        };

        // alert; confirm; prompt
        let type = this.props.type || 'alert';
        let title = this.props.title || '';
        let title1 = this.props.title1 || '';
        return (
            <Modal
                isOpen={this.props.isOpen}
                onAfterOpen={() => {
                    this.props.onOpen && this.props.onOpen();
                }}
                closeTimeoutMS={0}
                shouldCloseOnOverlayClick={true}
                contentLabel="Modal"
                style={customStyles}>
                <div className="imodal-module">
                    <div className="imodal-header">
                        <i className="imodal-close x-icon x-icon-stat-close"
                            onClick={(e) => {
                                e.preventDefault();e.stopPropagation();
                                this.close();
                            }}></i>
                        <h3 className='title'>{title}</h3>
                        <div className='title1'>{title1}</div>
                    </div>
                    {
                        type === 'custom' ? this.props.children :
                        (
                        <div>
                            {this.getContentView(type)}
                            {this.getActionView(type)}
                        </div>
                        )
                    }
                </div>
            </Modal>
        );
    }
    getContentView(type) {
        var content = this.props.content;
        return type === 'prompt' ?
        (
            <p className="imodal-body">
                <input ref="input" type="text" />
            </p>
        ) :
        (
            !!content ? (<p className="imodal-body">{content}</p>) : null
        );
    }
    getActionView(type) {
        let cancelText = this.props.cancelText || '取消';
        let okText = this.props.okText || '确定';

        return type === 'alert' ? null : (
            <div className="imodal-footer">
                <span className="imodal-msg" ref="msg"></span>
                <button onClick={(e) => {
                    e.preventDefault();e.stopPropagation();
                    this.props.onCancel && this.props.onCancel();
                    this.close();
                }}>{cancelText}</button>
                <button className="btn-ok"
                    onClick={(e) => {
                        e.preventDefault();e.stopPropagation();
                        this.ok();
                    }}>{okText}</button>
            </div>);
    }
    ok(){
        let cb = this.props.onOk || (() => {});
        let isPrompt = this.props.type === 'prompt';
        let value = '';
        if (isPrompt) {
            value = this.refs.input.value;
            if (!value) {
                this.refs.msg.innerHTML = '不能为空';
                return;
            }
        }
        cb(value);
        this.close();
    }
    close() {
        this.reset();
        this.props.onClose && this.props.onClose();
    }
    reset() {
        if (this.refs.input) {
            this.refs.value = '';
        }
        if (this.refs.msg) {
            this.refs.msg.innerHTML = '';
        }
    }
}
