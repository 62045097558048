import {
  CONSTANTS
} from './actions';
var initState = {
  userInfo: {},
  showAlert: false,
  alert: {},
  showToastr: false,
  toastr: '',
  showLoading: false,
  loading: '',
  id: 0,
  orders: [],
  adminOrders: [],
};

export default function home(state = initState, action) {
  switch (action.type) {
  case CONSTANTS.GET_USER_INFO:
    return Object.assign({}, state, {
      userInfo: action.data.data
    });
  case CONSTANTS.GET_USER_ORDER:
    return Object.assign({}, state, {
      orders: action.data.data
    });
  case CONSTANTS.GET_USER_ADMIN_ORDER:
    return Object.assign({}, state, {
      adminOrders: action.data.data
    });
  case CONSTANTS.SHOW_ALERT:
    var alert = Object.assign({}, state.alert, {
      title: action.data.title,
      msg: action.data.msg
    });
    return Object.assign({}, state, {
      alert: alert,
      showAlert: true
    });
  case CONSTANTS.HIDE_ALERT:
    return Object.assign({}, state, {
      showAlert: false
    });
  case CONSTANTS.SHOW_TOASTR:
    return Object.assign({}, state, {
      toastr: action.data,
      showToastr: true
    });
  case CONSTANTS.HIDE_TOASTR:
    return Object.assign({}, state, {
      showToastr: false,
      toastr: ''
    });
  case CONSTANTS.SHOW_LOADING:
    return Object.assign({}, state, {
      loading: action.data,
      showLoading: true
    });
  case CONSTANTS.HIDE_LOADING:
    return Object.assign({}, state, {
      showLoading: false,
      loading: ''
    });
  case CONSTANTS.GET_LANGUAGE:
    return Object.assign({}, state, {
      language: action.data
    });
  default:
    return state;
  }
}
