import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import DocumentTitle from "react-document-title";

import Utils from "../../services/utils";
import Nav from "../../components/global/nav/Index";
import Prof from "../../components/user/prof/Index";
import Order from "../../components/user/order/Index";
import OrderAdmin from "../../components/user/orderAdmin/Index";
import Request from "../../services/request";

import * as homeActions from "../home/actions";

require("./style.scss");

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canSubmit: false,
      countTime: 0,
      tab: this.props.pathname,
    };
  }
  componentDidMount() {
    // console.log(this.state.lesson);
  }
  logout() {
    Request("/api/users/logout")
      .then((data) => {
        window.location.href = '/login'
      })
      .catch((err) => {
        this.props.toastr(err.msg);
      });
  }
  render() {
    let { home } = this.props;
    let pathname = this.props.location.pathname.split("/")[2];
    if (!home.userInfo && !home.userInfo.u_name) {
      return null;
    }
    return (
      <DocumentTitle title={Utils.title()}>
        <div>
          <div className="container page-user">
            <div className="page-header">
              <div>
                <div className="user-photo"></div>
                <div className="user-name">
                  {home.userInfo.u_name || "未知用户"}
                </div>
              </div>
              {home.userInfo.role == 2 ? <button className="createQR" onClick={() => {this.props.history.push("/createQR")}}>创建推广分享图</button> : null}
              <ul className="nav">
                {home.userInfo.role == 2 ? <li className={pathname === "orderAdmin" ? "active" : null}>
                  <Link to="/user/orderAdmin">
                    <span>报名详情管理</span>
                  </Link>
                </li> : null}
                <li className={pathname === "prof" ? "active" : null}>
                  <Link to="/user/prof">
                    <span>我的资料</span>
                  </Link>
                </li>
                <li className={pathname === "order" ? "active" : null}>
                  <Link to="/user/order">
                    <span>我的订单</span>
                  </Link>
                </li>
              </ul>
            </div>
            {pathname === "orderAdmin" ? <OrderAdmin /> : null}
            {pathname === "prof" ? <Prof /> : null}
            {pathname === "order" ? <Order /> : null}
            <div className="logout" onClick={this.logout.bind(this)}>退出登录</div>
            <Nav />
            <div className="privacy">© 蝙蝠工场 2018</div>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

export default connect(
  (state) => {
    return {
      home: state.home,
    };
  },
  (dispatch) => {
    return {
      toastr: (msg) => {
        dispatch(homeActions.toastr(msg));
      },
    };
  }
)(Index);
