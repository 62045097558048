import Home from '../containers/home/Index';
import NotFound from '../components/global/not-found/Index';
import Index from '../containers/index/Index';
import reigster from './register';
import help from './help';
import login from './login';
import user from './user';
import userEdit from './user-edit';
import forgetPwd from './forget-pwd';
import feature from './feature';
import solutions from './solutions';
import join from './join';
import lessonList from './lesson-list';

export default [{
  component: Home,
  routes: [
    {
      path: '/',
      exact: true,
      component: Index
    },
    reigster,
    login,
    user,
    userEdit,
    forgetPwd,
    feature,
    solutions,
    help,
    join,
    lessonList,
  ]
}, {
  path: '*',
  component: NotFound
}];
