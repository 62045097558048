
import React, { Component } from 'react';
import { connect } from 'react-redux';
import c1Img from '../../assets/img/feature/c1.png'
import c2Img from '../../assets/img/feature/c2.png'
import c3Img from '../../assets/img/feature/c3.png'
import c4Img from '../../assets/img/feature/c4.png'
import c5Img from '../../assets/img/feature/c5.png'

class Index extends Component {
  componentDidMount() {
  }

  render() {
    return (
      <div className="container page-feature">
        <article>
          <p className="main-title">C端能力建设</p>
          <p className="second-title">对C端用户产出各类产品，解决用户核心诉求，扎根垂直方向</p>
          <img src={c1Img} style={{width: '2rem'}} />
          <div className='content-box'>
            <p className="content">
              我们为不同行业的客户提供定制化的产品，并使得这些产品在市场上得以成功落地。涵盖了从工县类应用到娱乐和生活服务应用，所有产品都以优雅的用户体验设计为核心。通过深度的用户行为分析和市场调研，产品团队能够精准地捕捉到消费者的需求和喜好，进而设计出易于使用且功能丰富的应用程序
            </p>
          </div>
          <img src={c2Img}/>
          <div className='content-box'>
            <p className="content">
              产品设计过程中，辅助用户思考落地能力及商业价值，帮助用户抽离核心逻辑，并通过核心逻辑制定原型和产品文档，交付用户。
            </p>
          </div>
          <img src={c3Img}/>
          <div className='content-box'>
            <p className="content">
              多角度服务客户，全技术栈赋能
            </p>
          </div>
          <img src={c4Img}/>
          <div className='content-box'>
            <p className="content">
              产品流程通过流程图直观展示给客户，与客户确认需求，并思考全流程业务逻辑，通过业务逻辑梳理完成后，进行下一步开发。
            </p>
          </div>
          <img src={c5Img}/>
        </article>
      </div>
    );
  }
}

export default connect(state => {
  return {
    home: state.home
  };
}, dispatch => {
  return {
  };
})(Index);