import React, { Component } from "react";
import { connect } from "react-redux";
import hengshenglogo from "../../../assets/img/solution/hengsheng-logo.png";
import hs1Img from "../../../assets/img/solution/hs1.png";

require("./style.scss");

class Index extends Component {
  componentDidMount() {}

  render() {
    var { home } = this.props;
    return (
      <div className="components-corp child-solution">
        <div className="selection-1">
          <div className="title-box">
            <p>恒升智算（AI Town）</p>
            <img src={hengshenglogo} />
          </div>
          <div className="subtitle">
            恒升智算成立于天津武清高村科技园区，是AI模型应用、垂直模型和AI教育科研公共服务平台
          </div>
          <div className="subtitle">
            AI算力本地化部署，可为高校教育机构提供实训基地、培训环境；提供与算力中心及数据中心光纤直连的，1200平米办公环境；数据可本地化部署，解决金融、医疗等行业数据脱敏繁琐、数据上网泄密等问题。
          </div>
          <ul className="subcontent-ul">
            <li>面向开发者、数据科学家、行业专家和研究人员</li>
            <li>配置英伟达H系GPU算力及开发环境、100T并行存储</li>
            <li>配置（可扩展）海飞科C系列信创GPU算力及开发环境</li>
            <li>京津冀地区0延时网络传输，周边丰富数据中心资源</li>
            <li>距天安门49kw，1200平，算力、数据直连办公环境</li>
            <li>权威AI团队忒伊亚科技进驻，多行业AI应用技术支持</li>
            <li>青云科技公有云模式算力调度及光纤直连算力调度双模式可选</li>
            <li>云平台访问方式：https://www.qingcloud.com/选择华北二区</li>
          </ul>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => {
    return {
      home: state.home,
    };
  },
  (dispatch) => {
    return {};
  }
)(Index);
