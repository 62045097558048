import React, { Component } from 'react';
import Modal from 'react-modal';

require('./style.scss');

export default class Loading extends Component {
    render() {
        const style = {
            content : {
                top                   : '50%',
                left                  : '50%',
                right                 : 'auto',
                minWidth: '0.69rem',
                padding: '7px 0.16rem',
                bottom                : 'auto',
                marginRight           : '-50%',
                background: 'rgba(51,51,51,0.54)',
                transform             : 'translate(-50%, -50%)'
            },
            overlay: {
                zIndex: 999
            }
        };

        var { content, isOpen } = this.props;

        return (
            <Modal
                isOpen={isOpen}
                contentLabel="Loading"
                style={style}>
                <div className="loading-module">
                    <div className="loading-body" ref="content">
                        {content || '...'}
                    </div>
                </div>
            </Modal>
        );
    }
}
