import Component from '../containers/user/Index';

export default {
  path: '/user',
  component: Component,
  routes: [
    {
      path: '/user/order',
      component: Component
    },
    {
      path: '/user/prof',
      component: Component
    }
  ]
}