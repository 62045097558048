import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

require("./style.scss");

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: this.props.pathname,
    };
  }
  componentDidMount() {}

  render() {
    var { home } = this.props;
    return (
      <div className="solution-banner">
        <p>关于忒伊亚</p>
        <ul className="nav">
          <li className={this.props.pathname == "company" ? "active" : null}>
            <Link to="/solutions/company">
              <span>公司简介</span>
            </Link>
          </li>
          <li className={this.props.pathname == "team" ? "active" : null}>
            <Link to="/solutions/team">
              <span>企业动态</span>
            </Link>
          </li>
          <li className={this.props.pathname == "corp" ? "active" : null}>
            <Link to="/solutions/corp">
              <span>合作企业</span>
            </Link>
          </li>
        </ul>
      </div>
    );
  }
}

export default connect(
  (state) => {
    return {
      home: state.home,
    };
  },
  (dispatch) => {
    return {};
  }
)(Index);
