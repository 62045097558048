import React, { Component } from 'react';

require('./style.scss');

export default class NotFound extends Component {
    render() {
        return (
            <div className="not-found-module">
                <p className="p-404">404</p>
                <p className="goback">您进入了未知世界！<br/>点击链接返回：<a href="/">返回首页</a></p>
            </div>
        );
    }
}
