import Component from '../containers/user-edit/Index';

export default {
  path: '/user-edit',
  component: Component,
  routes: [
    {
      path: '/user-edit/prof',
      component: Component
    },
    {
      path: '/user-edit/company',
      component: Component
    },
    {
      path: '/user-edit/school',
      component: Component
    }
  ]
};
