import React, { Component } from 'react';
import { connect } from 'react-redux';
import constants from '../../../constants/options';
import Utils from '../../../services/utils';

import * as homeActions from '../../../containers/home/actions';

require('./style.scss');

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentDidMount() {
    this.props.getOrderList();
    // console.log(this.state.lesson);
  }
  render() {
    let { home } = this.props;
    if (!home.orders && !home.orders.length) {
      return null;
    }
    return (
      <div className="user-order">
        <ul>
          {home.orders && home.orders.length ? home.orders.map((item, idx) => {
            return (
              <li key={idx} className="order-list">
                <div className="order-info">
                  <div className="order-left">
                    <p className="order-title">{Utils.getLesson(item.commodity_id).category}-{Utils.getLesson(item.commodity_id).title}</p>
                    <p className="order-no">订单编号：{item.order_id}</p>
                    <p className="order-time">创建时间：{item.create_time ? Utils.formatDatetime(item.create_time) : ''}</p>
                    {item.start_time ? <p className="order-time">预约时间：{Utils.formatDatetime(item.start_time) || ''}</p> : null}
                  </div>
                  <div className="order-right">
                    <p>{item.status_desc || ''}</p>
                  </div>
                </div>
              </li>
            );})
            : null
          }
        </ul>
        {!home.orders || !home.orders.length ?
          <p className="nodata">暂无订单记录</p>
          : null
        }
      </div>
    ); 
  }
}

export default connect(state => {
  return {
    home: state.home
  };
}, dispatch => {
  return {
    toastr: (msg) => {
      dispatch(homeActions.toastr(msg));
    },
    getOrderList: () => {
      dispatch(homeActions.getOrderList());
    }
  };
})(Index);
