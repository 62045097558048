import React, { Component } from 'react';
import { connect } from 'react-redux';
import b1Img from '../../assets/img/feature/b1.png'
import b2Img from '../../assets/img/feature/b2.png'
import b3Img from '../../assets/img/feature/b3.png'
import b4Img from '../../assets/img/feature/b4.png'
import b5Img from '../../assets/img/feature/b5.png'

class Index extends Component {
  componentDidMount() {
  }

  render() {
    return (
      <div className="container page-feature">
        <article>
          <p className="main-title">企业级能力建设</p>
          <p className="second-title">针对企业开发更专业的平台，解决企业效能及推进等各类问题</p>
          <img src={b1Img} />
          <div className='content-box'>
            <p className="content">
              我们为企业提供一站式智能管理解决方案。我们定制开发的SaaS化B端后台管理系统是一个多功能、可高度定制化的解决方案，专为简化企业的各种复杂问题而设计系统核心特点包括但不限于: 模块化设计、智能数据分析自动化流程、无缝集成、用户友好的界面、高度的安全性
            </p>
          </div>
          <img src={b2Img} />
          <div className='content-box'>
            <p className="content">
              全流程设计，从产品到设计到技术的一站式服务，最终输出甲方需要的产品能力。
            </p>
          </div>
          <img src={b3Img} />
          <img src={b4Img} />
          <div className='content-box'>
            <p className="content">
              交付流程及开发流程可追踪，实时同步进度与开发情况，控制ddl的最终交付日期。并保证整体质量服务企业服务标准。
            </p>
          </div>
          <img src={b5Img} />
        </article>
      </div>
    );
  }
}

export default connect(state => {
  return {
    home: state.home
  };
}, dispatch => {
  return {
  };
})(Index);