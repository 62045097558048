import React, { Component } from "react";
import { connect } from "react-redux";
import DocumentTitle from "react-document-title";

import Utils from "../../services/utils";
import Nav from "../../components/global/nav/Index";
import { withRouter } from "react-router-dom";

require("./style.scss");

class Index extends Component {
  componentDidMount() {}

  render() {
    var { home } = this.props;
    return (
      <DocumentTitle title={Utils.title()}>
        <div>
          <div className="container page-join">
            <div className="join-banner">
              <p>加入我们</p>
              <p className="sub-title">职位描述 & 福利</p>
            </div>
            <div className="components-join child-solution">
              <div className="selection-1">
                <p className="title">OCR算法工程师</p>
                <p className="subtitle">职位描述：</p>
                <ul>
                  <li>
                    研究前沿图像处理及识别技术，如图像去噪、图像矫正、轮廓提取、特征检测、关键信息提取、模式识别等；
                  </li>
                  <li>
                    负责构建人脸识别、OCR识别算法，并对相关图像样本进行特征标注、模型训练、迭代优化，构建高性能识别系统；
                  </li>
                  <li>
                    通过持续优化智能识别算法和机器学习模型，提升图像文本识别、人脸识别的准确度和效率，并持续迭代优化；
                  </li>
                  <li>
                    对人脸的微特征(小图)、特殊字符的OCR识别进行深入研究，从算法上提升特殊场景下识别的准确率；
                  </li>
                  <li>
                    负责对模型的混合精度训练、分布式训练，完善算法，对训练好的模型部署上线到生成环境；
                  </li>
                </ul>
                <p className="subtitle">任职要求：</p>
                <ul>
                  <li>
                    熟悉linux开发环境，3年及以上的Python、OpenCV算法开发经验，可独立完成算法的开发和迭代优化；
                  </li>
                  <li>
                    在OCR领域、静态人脸识别领域有深入研究，了解该领域当前热点和前沿技术，熟悉OpenCV/TensorFlow等工具
                  </li>
                  <li>
                    对算法原理有深入的理解，对数据标准、模型预研、模型训练、算法迭代优化有丰富的实战经验；
                  </li>
                  <li>
                    熟悉机器学习、深度学习、模式识别领域相关算法，有过硬的OCR识别、人脸识别项目的主导落地经验；
                  </li>
                  <li>
                    对挑战性的业务充满热情，具有较强的协作能力、抗压能力、求知欲，具有创新性思维；
                  </li>
                </ul>
              </div>
              <div className="selection-1">
                <p className="title">算法工程师-NLP方向</p>
                <p className="subtitle">职位描述：</p>
                <ul>
                  <li>
                    搭建智能客服知识库及知识图谱，包括实体与关系抽取，语义搜索、实体链接及推荐等，以及图谱的表征学习、知识融合更新等；
                  </li>
                  <li>
                    基于对最新NLP自然语言理解与处理的基础模型和算法的创新研究，构建智能对话机器人，并能与现有的IM客服系统集成对接；
                  </li>
                  <li>
                    基于现有客服业务、线上交付业务，构建智能客服机器人，实现客服业务的无人智能应答，满足线上交易业务无人交付；
                  </li>
                  <li>
                    基于上下文语义的理解，对当前用户每句话进行语义理解+情感分析并打分，根据正向分大小结合知识库，做智能应答，促成交易
                  </li>
                </ul>
                <p className="subtitle">任职要求：</p>
                <ul>
                  <li>
                    3年及以上扎实的机器学习/NLP算法基础，扎实代码基础，熟练Python/C++/Java等一门，熟练掌握Tensorflow/PyTorch等深度学习框架；
                  </li>
                  <li>
                    有丰富的自然语言处理和深度学习的项目经验，基础扎实，对至少其中一个子领域的算法在深度/广度两个维度都有深入的理解和思考；
                  </li>
                  <li>
                    熟悉主流的预训练模型，至少从事过（文本分类/实体识别/语义匹配/关系抽取等）其中任一方向的算法模型开发任务并有实际业务落地经验；
                  </li>
                  <li>
                    熟悉羊驼、ChatGPT等大模型训练，能够0-1搭建智能客服机器人模型，在NLP模型的深入研究、模型的迭代优化方面有丰富的实战经验；
                  </li>
                  <li>
                    学习能力强，热爱思考问题，工作原创性高；动手能力强，独立编写和调试完整算法的丰富经验；逻辑能力强，善于交流讨论、以及分享；
                  </li>
                </ul>
                <p className="title">自荐方式</p>
                <p className="subtitle2">
                  Email: official@itheia.cn <br />{" "}
                  (请在邮件附件中上传简历，我们的HR会与您联系！)
                </p>
              </div>
            </div>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

export default connect(
  (state) => {
    return {
      home: state.home,
    };
  },
  (dispatch) => {
    return {};
  }
)(withRouter(Index));
